<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">认证详情</div>
    <!-- 认证通过 -->
    <div>
    <div class="card">
        <div class="catalog">
            <p>企业名称</p>
            <p>广州软件有限公司 <img style="height: 5.5vw; margin-top: 2vw" src="../../assets/img/rztg.png"></p>
        </div>
        <div class="catalog">
            <p>统一社会信用代码</p>
            <p>858264585885</p>
        </div>
        <div class="catalog">
            <p>法人姓名</p>
            <p>西边</p>
        </div>
        <div class="catalog">
            <p>法人身份证</p>
            <p>442536988752150147</p>
        </div>
        <div class="catalog">
            <p>认证方式</p>
            <p>企业工商信息+法人银行卡四要素</p>
        </div>
        <div class="catalog">
            <p>认证时间</p>
            <p>2016-10-21</p>
        </div>
        
    </div>
    <p class="title">银行卡四要素</p>
    <div class="card">
        <div class="catalog">
            <p>姓名</p>
            <p>西边</p>
        </div>
        <div class="catalog">
            <p>身份证</p>
            <p>442536988752150147</p>
        </div>
        <div class="catalog">
            <p>银行卡号</p>
            <p>66898233216465456</p>
        </div>
        <div class="catalog">
            <p>手机号</p>
            <p>13565698245</p>
        </div>
        
    </div>
    <p class="title">企业对公账户信息</p>
    <div class="card">
        <div class="catalog">
            <p>企业对公账户</p>
            <p>66898233216465456</p>
        </div>
        <div class="catalog">
            <p>开户行</p>
            <p>西边建设银行</p>
        </div>
        <div class="catalog">
            <p>银行卡号</p>
            <p>442536988752150147</p>
        </div>       
        
    </div>

    <div style="text-align: center;padding: 4vw 0;">
        <router-link to="/about" class="edit">返回</router-link>
        <router-link to="/realNameAuthC" class="edit">重新认证</router-link>
    </div>
    </div>

  </div>
</template>
<script>
import { ref } from 'vue';
export default {
   setup() {

    return { 

     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    p.title {
        font-size: 4vw;
        font-weight: 600;
        margin: 0 4vw;
    }
    .card {
        padding: 2vw 4vw;
        .catalog {
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding: 3vw 0;
            display: flex;
            p:first-child {
                flex:1;
                color: #666666;
                font-size: 4vw;
            }
            p:last-child {
                flex:2;
                font-size: 4vw;
                padding-left: 4vw;
            }
        }
        .catalog:last-child {
            border-bottom: 0;
        }
    }
    .edit {
        display: inline-block;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4vw;
        padding: 1vw 3vw;
        margin: 0 2vw;
        border-radius: 5vw;
        width: 16vw;
    }
    .pop {
        padding: 4vw;
        width: 70vw;        
        .pop-header {
            font-size: 5vw;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4vw;
        }
        .pop-confirm {
            .content {
                padding: 3vw 2vw;
                border-bottom: 1px solid #f5f5f5;
                display: flex;
                p{
                    font-size: 4vw;
                }
                p:first-child {
                    width: 34%;
                }
                p:last-child{
                    width: 66%;
                }
            }
            
             
        }

        .pop-footer {
            text-align: center;
            padding-top: 4vw;
            .next {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                margin-left: 4vw;
                width: 22vw;
            }
        }
    }
    
}
</style>
